<template>
  <header v-if="this.isLogin">
    <swt-loading-css v-if="isLoggingOut" />

    <div class="homebtn">
      <router-link
        v-bind:to="{ name: 'top' }"
        ><img
          v-bind:src="require('../assets/img/icon_b/home.png')"
          alt="ホームへ戻る"
        /></router-link
      >
    </div>

    <h1 id="page-title">
      <router-link
        v-bind:to="{ name: 'top' }"
        ><img
        v-bind:src="require('../assets/img/logo.png')"
          alt="トレンドサーチ"
        /></router-link
      >
    </h1>

    <div class="userbtn" v-on:click="toggle">
      <font-awesome-icon icon="user" />
    </div>
    <div
      class="userbox"
      v-bind:class="{ 'show': isActiveBox, 'hide': !isActiveBox }"
    >
      <p>{{ this.userData.name }}</p>
      <p v-on:click="logout" style="cursor: pointer">Logout</p>
    </div>
  </header>
</template>

<script>
import SwtLoadingCss from "../components/SwtLoadingCss";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SwtNavLogin",
  components: {
    SwtLoadingCss,
  },
  data() {
    return {
      isLoggingOut: false,
      isActiveBox: false,
    };
  },
  methods: {
    toggle() {
      this.isActiveBox = !this.isActiveBox
    },
    logout() {
      if (this.isLoggingOut) return;
      this.isLoggingOut = true;
      this.userLogout();
    },
    ...mapActions({
      userLogout: "auth/logout",
    }),
  },
  computed: {
    ...mapGetters({
      isLogin: "auth/check",
      userData: "auth/getUser",
      getData: "view/getData",
      getTitle: "view/getTitle",
    }),
  },
  watch: {
    isLogin: function (newVal) {
      if (newVal) this.isLoggingOut = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>