<template>
  <section>
    <swt-loading-css v-if="load" />

    <div id="cont-wrap">
      <h2>スキルアップメモ</h2>
      <p v-html="getParagraphText(detail)"></p>

      <div class="site-wrap">
        <div class="around-page ap4nopad">
          <div class="search-box">
            <div class="skillmemo-box">
              <label>
                <textarea
                  name="skillupmemo"
                  class="input-box"
                  cols="40" rows="6"
                  placeholder="メモ"
                  v-model="result.text"
                ></textarea>
                </label>
            </div>

            <div class="btn-line">
              <label class="sb-01">
                <button
                  type="button"
                  class="btn"
                  v-on:click="setMemoData()"
                >
                  <span>更新</span>
                </button>
              </label>

              <label class="sb-02">
                <button
                  type="button"
                  class="btn_01"
                  v-on:click="unsetMemoData()"
                >
                  <span>削除</span>
                </button>
              </label>
            </div>

            <div
              class="output-box"
              v-if="result.preview !== ''"
              v-html="getParagraphText(result.preview)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwtLoadingCss from "../components/SwtLoadingCss";
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "EditorMemo",
  components: {
    SwtLoadingCss,
  },
  data: function () {
    return {
      detail: ``,
      result: {
        text: "",
        preview: "",
      },
      load: false,
    };
  },
  created() {
    const data = this.getData("editorMemo");
    if (data) {
      this.result = data;
    } else {
      this.getMemoData();
    }
  },
  destroyed() {
    this.setData({ key: "editorMemo", value: this.result });
  },
  computed: {
    ...mapGetters({
      getData: "view/getData",
    }),
  },
  methods: {
    getParagraphText: function (text) {
      return text.trim().replace(/\r?\n/g, "<br/>");
    },
    getConvertText: function (text) {
      return text
        .replace(/</g, "&lt")
        .replace(/>/g, "&gt")
        .replace(/\n/g, "<br/>");
    },
    getMemoData: function () {
      if (this.load !== false) return;
      const url = process.env.VUE_APP_API_URL + "editorMemo/get";
      this.load = true;
      this.axios
        .get(url)
        .then((response) => {
          this.load = false;
          if (response.data) {
            const data = response.data;
            this.result.text = data["memo"];
            this.result.preview = this.getConvertText(data["memo"]);
            this.setData({ key: "editorMemo", value: this.result });
          }
        })
        .catch(() => {
          this.load = false;
          this.result.text = "";
          this.result.preview = "";
        });
    },
    setMemoData: function () {
      if (this.load !== false || this.result.text === undefined) return;
      const url = process.env.VUE_APP_API_URL + "editorMemo/set";
      let data = {};
      data["memo"] = this.result.text;
      this.load = true;
      this.axios
        .post(url, data)
        .then(() => {
          this.load = false;
          this.result.preview = this.getConvertText(this.result.text);
          this.setData({ key: "editorMemo", value: this.result });
        })
        .catch(() => {
          this.load = false;
        });
    },
    unsetMemoData: function () {
      this.result.text = "";
      this.setMemoData();
    },
    ...mapMutations({
      setData: "view/setData",
    })
  },
};
</script>

<style lang="scss" scoped>
</style>