<template>
  <nav class="menu_box">
  <ul>
    <li v-for="(page, navIndex) in this.navItems" v-bind:key="navIndex">
      <router-link
        v-bind:to="{ name: page.name }"
      >
        <img
          v-bind:src="require('../assets/img/' + page.icon)"
          v-bind:alt="page.display"
        />
        <p>{{ page.display }}</p>
      </router-link>
    </li>
  </ul>
  </nav>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "AboutPage",
  components: {},
  data() {
    return {};
  },
  created() {
  },
  computed: {
    navItems() {
      return [
        {
          name: "search_multi",
          display: "トレンド検索",
          icon: "icon_b/search.png",
        },
        {
          name: "access_recomme",
          display: "おすすめブログ",
          icon: "icon_b/sns.png",
        },
        {
          name: "search_seo",
          display: "SEO",
          icon: "icon_b/seo.png",
        },
        {
          name: "access_manual",
          display: "マニュアル",
          icon: "icon_b/manual.png",
        },
        {
          name: "editor_memo",
          display: "スキルUPメモ",
          icon: "icon_b/upup.png",
        },
        {
          name: "access_topics",
          display: "トピックス",
          icon: "icon_b/topics.png",
        },
      ].filter((d) => this.userData["active_" + d.name])
    },
    ...mapGetters({
      getData: "view/getData",
      userData: "auth/getUser",
    }),
  },
  methods: {
    ...mapMutations({
      setData: "view/setData",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>