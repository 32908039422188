<template>
  <section>
    <div id="cont-wrap">
      <h2>おすすめブログ</h2>

      <ul class="banner">
        <li
          class="banner_space"
          v-for="(page, navIndex) in this.navItems" v-bind:key="navIndex"
        >
          <a
            v-bind:href="page.url"
            target="_blank"
            rel="noopener"
          >
            <img
              v-bind:src="require('../assets/img/blog/' + page.icon)"
              v-bind:alt="page.display"
              class="waku"
            />
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "AccessRecommeBlog",
  components: {},
  data() {
    return {};
  },
  created() {
  },
  computed: {
    navItems() {
      return [
      {
          display: "ameba",
          icon: "ameba.jpeg",
          url: "https://official.ameba.jp/",
        },
        {
          display: "livedoor",
          icon: "livedoorblog.jpeg",
          url: "https://member.livedoor.com/login/",
        },
        {
          display: "FC2blog",
          icon: "FC2blog.jpeg",
          url: "https://blog.fc2.com/",
        },
      ].filter((d) => require('../assets/img/blog/' + d.icon))
    },
    ...mapGetters({
      getData: "view/getData",
      userData: "auth/getUser",
    }),
  },
  methods: {
    ...mapMutations({
      setData: "view/setData",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>