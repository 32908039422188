import router from "../../router";

import axios from "axios";
axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.withCredentials = true;

// eslint-disable-next-line
// const OK = 200
// eslint-disable-next-line
const CREATED = 201;
const UNAUTHORIZED_API = 401;
const NOT_FOUND = 404;
const UNAUTHORIZED = 419;
const UNPROCESSABLE_ENTITY = 422;
const TOO_MANY_REQUESTS = 429;
const INTERNAL_SERVER_ERROR = 500;

export default {
  namespaced: true,
  state: {
    user: null,
    error: null,
  },
  // state, value / state, { payload }
  mutations: {
    setUser: (state, user) => {
      state.user = user;
    },
    setError: (state, message) => {
      state.error = message;
    },
  },
  // context, value / context, { payload }
  // context.(state, getters, rootState, rootGetters, commit, dispatch)
  actions: {
    async getUser(context) {
      context.dispatch("error");
      const url = process.env.VUE_APP_API_URL + "user";
      await axios
        .get(url)
        .then((response) => {
          const user = response.data.name ? response.data : null;
          context.commit("setUser", user);
          if (user === null) {
            router.push("/login").catch(() => {});
          }
        })
        .catch((error) => {
          context.commit("setUser", null);
          context.dispatch("error", error.response);
          router.push("/login").catch(() => {});
        });
    },
    async login(context, data) {
      context.dispatch("error");
      const url = process.env.VUE_APP_API_URL + "login";
      await axios
        .post(url, data)
        .then((response) => {
          const user = response.data.name ? response.data : null;
          context.commit("setUser", user);
          if (user !== null) {
            router.push("/");
          } else {
            context.commit("setError", {
              message: "ログインに失敗しました",
            });
          }
        })
        .catch((error) => {
          context.commit("setUser", null);
          context.dispatch("error", error.response);
        });
    },
    async logout(context) {
      context.dispatch("error");
      const url = process.env.VUE_APP_API_URL + "logout";
      await axios
        .get(url)
        .then(() => {
          context.commit("setUser", null);
          router.push("/login");
        })
        .catch((error) => {
          context.commit("setUser", null);
          context.dispatch("error", error.response);
          router.push("/login");
        });
    },
    error(context, response) {
      if (response === undefined) {
        context.commit("setError", null);
      } else {
        const error = {
          status: response.status,
          message: "エラーが発生しました",
          errors: response.data.errors,
        };
        if (error.status === UNPROCESSABLE_ENTITY) {
          error.message = "処理に失敗しました";
        } else if (error.status === UNAUTHORIZED) {
          error.message = "許可されていません";
        } else if (error.status === UNAUTHORIZED_API) {
          error.message = "許可されていません(API)";
        } else if (error.status === NOT_FOUND) {
          error.message = "見つかりませんでした";
        } else if (error.status === TOO_MANY_REQUESTS) {
          error.message = "しばらく時間をおいてからアクセスしてください";
        } else if (error.status === INTERNAL_SERVER_ERROR) {
          error.message = "サーバーエラーが発生しました";
        }
        context.commit("setError", error);
      }
    },
  },
  // state, getters, rootState, rootGetters
  // (state, getters, rootState, rootGetters) => (value)
  getters: {
    check: (state) => !!state.user,
    checkActive: (state) => (param) => !!state.user["active_" + param],
    user: (state) => (param) => {
      return state.user[param] ? state.user[param] : "";
    },
    getUser: (state) => state.user,
    getError: (state) => state.error,
  },
};
