import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import store from "../store";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "top",
      component: require("../views/About").default,
    },
    {
      path: "/login",
      name: "login",
      component: require("../views/Login").default,
    },
    {
      path: "/search/multi",
      name: "search_multi",
      component: require("../views/SearchMulti").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "/search/seo",
      name: "search_seo",
      component: require("../views/SearchSeo").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "/editor/memo",
      name: "editor_memo",
      component: require("../views/EditorMemo").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "/access/recomme",
      name: "access_recomme",
      component: require("../views/AccessRecommeBlog").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "/access/manual",
      name: "access_manual",
      component: require("../views/AccessManual").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "/access/topics",
      name: "access_topics",
      component: require("../views/AccessTopics").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.dispatch("auth/getUser");
  const isLogin = store.getters["auth/check"];
  // ログイン状態でログイン画面に遷移しようとしたらトップへ
  if (isLogin && to.path === "/login") {
    next("/");
  }
  // ログインしていないならログイン画面に遷移する
  else if (!isLogin && !to.path.match(/\/login(\/.*)?/)) {
    next("/login");
  } else {
    next();
  }
});

export default router;
