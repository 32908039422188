<template>
  <section>
    <swt-loading-css v-if="load" />

    <div id="cont-wrap">
      <h2>トピックス</h2>
      <p
        class="comment-line"
        v-html="getParagraphText(detail)"
      ></p>

      <div class="site-wrap">
        <div
          class="around-page ap4pad text9"
          v-html="getParagraphText(text)"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import SwtLoadingCss from "../components/SwtLoadingCss";
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "AccessTopics",
  components: {
    SwtLoadingCss,
  },
  data: function () {
    return {
      detail: ``,
      text: "",
      load: false,
    };
  },
  created() {
    this.getTopicsData();
  },
  computed: {
    ...mapGetters({
      getData: "view/getData",
    }),
  },
  methods: {
    getParagraphText: function (text) {
      return text.trim().replace(/\r?\n/g, "<br/>");
    },
    getConvertText: function (text) {
      return text
        .replace(/</g, "&lt")
        .replace(/>/g, "&gt")
        .replace(/\n/g, "<br/>");
    },
    getTopicsData: function () {
      if (this.load !== false) return;
      const url = process.env.VUE_APP_API_URL + "accessTopics/get";
      this.load = true;
      this.axios
        .get(url)
        .then((response) => {
          this.load = false;
          if (response.data) {
            const data = response.data;
            this.text = this.getConvertText(data["topics"]);
          }
        })
        .catch(() => {
          this.load = false;
        });
    },
    ...mapMutations({
      setData: "view/setData",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>