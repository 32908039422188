<template>
  <section>
    <div id="cont-wrap">
      <h2>トレンド検索</h2>
      <p
        class="comment-line"
        v-html="getParagraphText(detail)"
      ></p>

      <div class="site-wrap">
        <div class="around-page">
          <div class="search-box">
            <label>
              <input
                type="text"
                class="input-box"
                placeholder="検索ワード"
                v-model="query"
                v-on:keydown.enter="submit"
              />
            </label>
            <label>
              <button
                type="button"
                class="btn"
                v-on:click="getSearch"
              >
                <font-awesome-icon icon="magnifying-glass" />
                <span>検索</span>
              </button>
            </label>
          </div>
        </div>
      </div>

      <div class="flex-cont" v-for="(e, i) in getActiveEngine" v-bind:key="i">
        <swt-loading v-if="load[e]" />

        <div v-if="result.search[e].length > 0">
          <div class="browser-title">
            <img v-bind:src="require('../assets/img/search/' + e + '_logo.svg')" />
          </div>
          <p v-for="(data, index) in result.search[e]" v-bind:key="index">
            <a v-bind:href="data.href" target="_blank">{{ data.text }}</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwtLoading from "../components/SwtLoading";
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "SearchMulti",
  components: {
    SwtLoading,
  },
  data: function () {
    return {
      detail: `
内容キーワード検索
                `,
      query: "",
      engine: [
        "google",
        // "yahoo",
        // "bing",
      ],
      result: {
        search: {
          google: [],
          // yahoo: [],
          // bing: [],
        },
        url: {
          google: "",
          // yahoo: "",
          // bing: "",
        },
        active: {
          google: true,
          // yahoo: true,
          // bing: true,
        },
      },
      load: {
        google: false,
        // yahoo: false,
        // bing: false,
      },
    };
  },
  computed: {
    getActiveEngine: function () {
      return this.engine.filter((d) => {
        return (
          (this.result.search[d].length > 0 || this.load[d]) &&
          this.result.active[d] === true
        );
      });
    },
    ...mapGetters({
      getData: "view/getData",
    }),
  },
  created() {
    this.ls = {
      key: "searchMulti",
      target: ["result", "query"],
    };
    const data = this.getData(this.ls.key);
    if (data) {
      this.ls.target.forEach((target) => {
        if (data[target]) {
          this[target] = data[target];
        }
      });
    }
  },
  destroyed() {
    let data = {};
    this.ls.target.forEach((target) => {
      data[target] = this[target];
    });
    this.setData({ key: this.ls.key, value: data });
  },
  methods: {
    getParagraphText: function (text) {
      return text.trim().replace(/\r?\n/g, "<br/>");
    },
    submit: function (e) {
      // 通常のエンター以外は無視(日本語変換中など)
      // keydown.enter: e.keyCode === 229
      if (e.keyCode !== 13) return;
      this.getSearch();
    },
    getSearch: function () {
      if (this.query === "") return;
      this.engine.forEach((e) => {
        if (this.result.active[e] === false || this.load[e] !== false) return;
        const url =
          process.env.VUE_APP_API_URL + "search/" + e + "/" + this.query;
        this.load[e] = true;
        this.result.search[e] = [];
        this.axios
          .get(url)
          .then((response) => {
            this.load[e] = false;
            if (response.data[0]) {
              response.data[1].forEach((node) => {
                this.result.search[e].push({
                  text: node.title,
                  href: node.href,
                });
              });
              this.result.url[e] = response.data[0];
            }
          })
          .catch(() => {
            this.load[e] = false;
          });
      });
    },
    ...mapMutations({
      setData: "view/setData",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>