<template>
  <section>
    <div id="cont-wrap">
      <h2>マニュアル</h2>
      <p
        class="comment-line"
        v-html="getParagraphText(detail)"
      ></p>

      <div class="site-wrap">
        <div
          class="around-page ap4pad text9"
          style="height: 40vh; padding: 0;"
        >
          <pdfjs-viewer-element
            style="height: 100%;"
            v-bind:src="viewerSrc"
            v-bind:viewer-path="viewerPath"
          />
        </div>
      </div>

      <div class="btn_02">
        <label>
          <button type="button" class="btn" v-on:click="pdfDownload()">
            <font-awesome-icon icon="file-arrow-down" />
            <span>マニュアルをDL</span>
          </button>
        </label>
      </div>
    </div>
  </section>
</template>

<script>
import 'pdfjs-viewer-element';
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "AccessManual",
  components: {
  },
  data: function () {
    return {
      detail: ``,
      viewerSrc: process.env.VUE_APP_MANUAL_URL,
      viewerPath: "/js/pdf",
    };
  },
  computed: {
    ...mapGetters({
      getData: "view/getData",
    }),
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    getParagraphText: function (text) {
      return text.trim().replace(/\r?\n/g, "<br/>");
    },
    pdfDownload: function () {
      const obj = document.createElement('a');
      obj.href = this.viewerSrc;
      obj.download = `manual.pdf`;

      document.body.appendChild(obj);
      obj.click();
      obj.parentNode.removeChild(obj);
    },
    ...mapMutations({
      setData: "view/setData",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>